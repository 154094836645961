import React from "react";

export const DevOnly = ({
  children,
  env = "dev",
}: { children: React.ReactNode; env?: "prod" | "dev" }) => {
  if (env === "prod") {
    return null;
  }
  return <>{children}</>;
};

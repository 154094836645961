"use client";
import { cn } from "@utils/cn";
import { AnimatePresence, motion, MotionProps } from "framer-motion";
import { CheckIcon, LoaderIcon, RotateCcw, XCircleIcon } from "lucide-react";
import { ComponentPropsWithoutRef, forwardRef } from "react";

export type StatefulType = "success" | "loading" | "failed" | "idle";

export interface StatefulContentProps
  extends MotionProps,
    ComponentPropsWithoutRef<typeof motion.span> {
  state: StatefulType;
}

export const StatefulContent = forwardRef<
  HTMLSpanElement,
  StatefulContentProps
>(({ state = "idle", children, ...props }, ref) => {
  const iconMap = {
    loading: <LoaderIcon className="size-6 animate-spin" />,
    success: <CheckIcon className="size-6" />,
    failed: <XCircleIcon className="size-6" />,
    retry: <RotateCcw className="size-6" />,
  };

  return (
    <AnimatePresence>
      {state !== "idle" && (
        <motion.span
          key={state}
          initial={{ opacity: 0, y: -10 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: 10 }}
          transition={{ duration: 0.2 }}
          data-state={state}
          ref={ref}
          style={{ position: "absolute", pointerEvents: "none" }}
          {...props}
        >
          {iconMap[state]}
        </motion.span>
      )}
      <motion.span
        animate={{ opacity: state === "idle" ? 1 : 0 }}
        className={cn(props.className)}
      >
        {children}
      </motion.span>
    </AnimatePresence>
  );
});
StatefulContent.displayName = "StatefulContent";
